<template>
  <!-- <div class="SlowMovingLocationItem"> -->
      <div class="content-card">
        <div class="card-header card-header-flex-center rounded-t-lg bg-brand text-center font-bold  p-3">
          <p>{{data.name}}</p>
        </div>
        <div class="card-content text-center rounded-b-lg bg-card-content p-3">
          <div class="row">
              <div class="col-6"><b>Ausstattung</b><br>
                <span v-for="feature in data.features" :key="feature.id">
                  {{feature.facilityfeatures.text}}
                </span>
              </div>
              <div class="col-6"><b>Linien</b><br>
                <span v-for="lines in data.lines" :key="lines.id">
                  {{lines.lines_id.name}}
                </span>
              </div>
          </div>
          <div class="space"></div>
           <p>{{data.description}}</p>
        </div>
      </div>
  <!-- </div> -->
</template>

<script>
export default {
  name: "FacilityItem",
  props: {
    data: null
  },
  methods: {},
};
</script>
