<template>
  <div id="Facilities">
    <h1 class="brand-color-dark  headline">Aufenthaltsräume & Sanitäreinrichtungen</h1>
        
    <NoDataBox v-if="data.length <= 0 && !isLoading" headline="Keine Informationen vorhanden">Schaue später wieder vorbei.</NoDataBox>

    <p v-show="isLoading">
      <font-awesome-icon icon="spinner" class="icon" pulse /> Daten werden geladen...
    </p>

    <div class="content-card-container grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-4 my-3 items-start" v-if="data">
      <FacilityItem :data="d" v-for="d in data" :key="d.id"></FacilityItem>
    </div>
  </div>
</template>


<script>
import NoDataBox from "@/components/NoDataBox";
import FacilityItem from "@/components/FacilityItem";

import { mapGetters } from "vuex";

export default {
  name: "Facilities",
  components: {
    NoDataBox,
    FacilityItem
  },
  data() {
    return {
      data: [],
      isLoading: true
    };
  },
  methods: {},
  mounted() {
    this.$store.dispatch("fetchUserProfile");

    this.$store.dispatch("fetchBoardFacilities").then((a) => {
      if (a.status == true) {
        this.data = a.result
      }

      this.isLoading = false
    });
  },
  computed: {
    ...mapGetters({
      user: "getUser"
    }),
  },
};
</script>